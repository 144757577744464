import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ClassTimetable } from 'src/app/domain';
import { environment } from 'src/environments/environment';
import { MazemapService } from './mazemap.service';

@Injectable({
  providedIn: 'root',
})
export class TimetableService {
  private _apiServer = environment.apiServer;
  private _timetable = new BehaviorSubject<ClassTimetable[]>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);

  timetable$: Observable<ClassTimetable[]>;
  loading$: Observable<boolean>;

  filterUpcommingClasses = pipe(
    tap((a) => console.debug('full day timetable', a)),
    filter((a) => !!a),
    map((results: ClassTimetable[]) => {
      const currentDate = new Date();
      const hour = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      return results.filter((r) => {
        const time = r.endTime.split(':');
        if (Number(time[0]) < hour || (Number(time[0]) === hour && Number(time[1]) <= minutes)) {
          return false;
        }
        return true;
      });
    })
  );

  constructor(private _http: HttpClient, private _mazeMap: MazemapService) {
    this.timetable$ = this._timetable.asObservable();
    this.loading$ = this._loading.asObservable();
  }

  async getTimetable(): Promise<void> {
    this._timetable.pipe(filter((t) => t === undefined)).subscribe((data) => {
      this._loading.next(true);
      this.getTimetableObservable()
        .pipe(
          map((result) => result.data),
          this.filterUpcommingClasses
        )
        .subscribe(
          async (result: ClassTimetable[]) => {
            await this._updatePois(result);
          },
          (error: any) => {
            console.log(error);
            this._loading.next(false);
          }
        );
    });
  }

  getTimetableObservable(): Observable<any> {
    return this._http.get(`${this._apiServer}student-timetable`);
  }

  private async _updatePois(timetable: ClassTimetable[]): Promise<void> {
    // this.addMoreClasses(timetable);
    for (const iterator of timetable) {
      const poi = await this._mazeMap.searchPoiDetails(iterator.facilityCode);
      iterator.poi = this._getClassPoi(poi);
    }

    this._timetable.next(timetable);
    this._loading.next(false);
  }

  private _getClassPoi(poi: any): string {
    let url = '';
    const item = poi.results.features[0];
    if (item.properties.poiId) {
      url = item.properties.poiId;
    }
    return url;
  }

  // for local testing only
  addMoreClasses(timetable) {
    const currentDate = new Date();
    const hour = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    for (let k = 1; k <= 2; k++) {
      const data = { ...this.data };
      data.startTime = `${hour}:${minutes}`;
      data.endTime = `${hour}:${minutes + k}`;
      timetable.push(data);
    }
  }

  // tslint:disable-next-line:member-ordering
  data = {
    date: '2020-09-03',
    courseTitle: 'Landscape, Environment and Heritage',
    facilityCode: '119-G10',
    catalogNumber: '352',
    classSection: 'T02C',
    facilityDescription: 'Clock Tower - East, Room G10',
    subject: 'GEOG',
    buildingCode: '119',
    startTime: '09:00',
    endTime: '11:00',
    courseId: '001498',
    classType: 'Tutorial',
  };
}
