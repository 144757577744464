import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Note: loader import location set using "esmLoaderPath" within the output target config
import { defineCustomElements } from '@uoa/web-components/loader';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
// The timeout below is a workaround for a Stencil issue that prevents
// the ionic menu from working in Angular: https://github.com/ionic-team/stencil/issues/3105
setTimeout(() => defineCustomElements(), 0);
