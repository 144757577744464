import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { MenuService } from 'src/app/core/services';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {
  get showMenu(): boolean {
    return this._menuService.showMenu;
  }

  constructor(private _menuCtrl: MenuController, private _menuService: MenuService) {}

  openMenu() {
    this._menuCtrl.open();
  }
}
