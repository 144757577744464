import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit  {
  ngOnInit(): void {
    this.initializeGoogleAnalytics();
  }

  /**
   * Loads Google Analytics scripts. See https://developers.google.com/tag-manager/quickstart
   */
  initializeGoogleAnalytics(): void {
    if (environment.googleTag && environment.googleTag !== '') {
      // Load Google Tag Manager script
      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${environment.googleTag}');
    `;

      document.head.appendChild(gtmScript);

      // Start tracking users
      const gtmIframe = document.createElement('iframe');
      gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.googleTag}`;
      gtmIframe.height = '0';
      gtmIframe.width = '0';
      gtmIframe.style.display = 'none';
      gtmIframe.style.visibility = 'hidden';

      document.body.appendChild(gtmIframe);
    }
  }
}
