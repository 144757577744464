import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  showMenu = true;

  constructor(private _route: ActivatedRoute) {
    this.hideMenu();
  }

  /***
   * Optionally hides the menu and menu button for when embedding Wayfinding in the Kahu Android app, as Kahu
   * has it's own Menu. Showing both was confusing to the user.
   * @private
   */
  private hideMenu(): void {
    this._route.queryParamMap.subscribe((paramMap: ParamMap) => {
      const hideMenu = paramMap.get('hideMenu');
      this.showMenu = hideMenu == null || hideMenu.toLowerCase().trim() !== 'true';
    });
  }
}
