import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ClassTimetable, LocationType } from 'src/app/domain';

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.scss'],
})
export class ClassComponent {
  @Input() classTimeTable: ClassTimetable;
  constructor(private _router: Router, private _menuCtrl: MenuController) {}

  gotoLocation(): void {
    this._router.navigate([], {
      queryParams: { type: LocationType.ROOM, selectedLocation: this.classTimeTable.poi },
    });
    this._menuCtrl.close();
  }
}
