import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Feature } from 'src/app/domain';

declare const Mazemap: any;

@Injectable({
  providedIn: 'root',
})
export class MazemapUIService {
  /**
   * Virtual Assisant on the UoA website can show embedded Wayfinding maps when the user searches for, "Where is
   * the library?" (for example).
   * This boolean is used to make better use of the small UI space inside the Virtual Assistant chat window.
   */
  showMinimalUI = false;

  constructor(private _route: ActivatedRoute) {
    this.toggleMinimalUI();
  }

  // When Poi Titles are displayed in search results as as "(Room Code) (Poi Name)", exclude these
  // Poi Names that can be interpreted as a Room Code and display the Room Code instead.
  private _poiNameExceptions = [
    '205 TCHSEM',
  ];

  getBuildingName(title: string) {
    if (title == null) return null;

    const startPosition = title.lastIndexOf('(');

    // Last 6 characters are building code + brackets
    return title.slice(0, startPosition - 1);
  }

  /**
   * Get the building number from the Mazemap API response:
   * e.g. buildName: <em>Owen</em> <em>G</em> <em>G</em>lenn Building (260)
   * returns: 260
   */
  getBuildingNumber(buildingName: string) {
    if (buildingName == null) return null;

    let buildingCode;

    if (buildingName.indexOf('(') !== -1) {
      buildingCode = buildingName.substring(
        buildingName.indexOf('(') + 1,
        buildingName.lastIndexOf(')')
      );
    } else {
      buildingCode = buildingName.substring(
        buildingName.indexOf(' ') + 1
      );
    }

    return buildingCode;
  }

  getFloor(result: Feature) {
    if (result == null || result.properties == null  || result.properties.floors == null) return null;
    const poi = Mazemap.Data.getBuilding(result.properties.id);
    return `Floor: ${poi.__zone_symbol__value.properties.floors.find(floor => floor.z === 1)?.name}`;
  }

  /**
   * Poi Titles are displayed in search results as:
   * (Room Code) (Poi Name) - When there is a Room Code and Poi Name,
   * (Room Code) - When there is no Poi Name,
   * "Storage" - when there are no display names
   * @param names {string[]} Display Poi Names from MazeMaps API response.
   */
  getPoiTitle(names: string[]) {
    let result = 'Storage';

    if (names == null || names.length === 0) {
      return result;
    }

    const roomCode = this.getRoomCode(names);
    const poiName = this.getPoiName(names);

    if (roomCode == null || roomCode === poiName) {
      result = poiName;
    } else if (roomCode != null && poiName != null) {
      result = `${roomCode} ${poiName}`;
    } else {
      result = roomCode;
    }

    return result;
  }

  private getRoomCode(poiNames: string[]){
    if (poiNames == null) return '';

    return poiNames.find(element => /([0-9]-){1,3}.+/g.test(element)) // Find Room code with a hyphen first
      || poiNames.find(element => /([0-9]){1,3}.+/g.test(element));
  }

  private getPoiName(poiNames: string[]) {
    if (poiNames == null) return '';

    return poiNames.find(
      element => /([A-Z]){1}\w+/g.test(element) &&
      this._poiNameExceptions.some(exception => exception === element) === false);
  }

  private toggleMinimalUI(): void {
    this._route.queryParamMap.subscribe((paramMap: ParamMap) => {
      const showMinimalUI = paramMap.get('minimal');
      this.showMinimalUI = showMinimalUI != null && showMinimalUI.toLowerCase().trim() === 'true';
    });
  }
}
