import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

declare const Mazemap: any;

@Injectable({
  providedIn: 'root',
})
export class MazemapService {
  // options: search
  private _searchOptions: any = {
    campuscollectiontag: 'universityofauckland',
    rows: 50,
    start: 0,
    page: 0,
    withcampus: false,
    withbuilding: true,
    withpois: true,
    withtype: false,
    resultsFormat: 'geojson',
  };

  // options: poi search
  private _poiSearchOptions: any = {
    campuscollectiontag: 'universityofauckland',
    rows: 1,
    start: 0,
    page: 0,
    withcampus: false,
    withbuilding: false,
    withpois: true,
    withtype: false,
    resultsFormat: 'geojson',
  };

  // options: building search
  private _buildingSearchOptions: any = {
    campuscollectiontag: 'universityofauckland',
    rows: 1,
    start: 0,
    page: 0,
    withcampus: false,
    withbuilding: true,
    withpois: false,
    withtype: false,
    resultsFormat: 'geojson',
  };

  // options: category search
  private _categoryOptions: any = {
    campuscollectiontag: 'universityofauckland',
    rows: 2000,
    withpois: true,
    withbuilding: false,
    withtype: false,
    withcampus: false,
    resultsFormat: 'geojson',
  };

  // options: direction search
  private _directionOptions: any = {
    campuscollectiontag: 'universityofauckland',
    rows: 50,
    start: 0,
    page: 0,
    withcampus: false,
    withbuilding: false,
    withpois: true,
    withtype: false,
    resultsFormat: 'geojson',
  };

  // Map popup
  private _popupHtml: string =
    '<div class="popup-line">' +
    '<ion-button icon-only fill="clear" id="end" class="background-light"><span class="color-dark-1">Assign to</span>' +
    '<span class="popup-link">Destination</span><span><ion-icon name="chevron-forward-outline" class="popup-icon-inner"></ion-icon></span></ion-button>' +
    '</div>' +
    '<div>' +
    '<ion-button icon-only fill="clear" id="start" class="background-light"><span class="color-dark-1">Assign to</span>' +
    '<span class="popup-link">Start</span><span><ion-icon name="chevron-forward-outline" class="popup-icon-inner"></ion-icon></span></ion-button>' +
    '</div>' +
    '</div>';

  searchControl: any = null;
  // public categoryControl: any = null;

  constructor(private http: HttpClient) {}

  initializeSearchControl(): void {
    this.searchControl = new Mazemap.Search.SearchController(this._searchOptions);
  }

  searchPoiDetails(query: string): Promise<any> {
    const searchControl = new Mazemap.Search.SearchController(this._poiSearchOptions);
    return searchControl.search(query);
  }

  searchBuildingDetails(query: string): Promise<any> {
    const searchControl = new Mazemap.Search.SearchController(this._buildingSearchOptions);
    return searchControl.search(query);
  }

  search(query: string): Promise<any> {
    return this.searchControl.search(query);
  }

  categorySearch(query: string): Promise<any> {
    const categoryControl = new Mazemap.Search.SearchController(this._categoryOptions);
    return categoryControl.search(query);
  }

  directionSearch(query: string): Promise<any> {
    const directionControl = new Mazemap.Search.SearchController(this._directionOptions);
    return directionControl.search(query);
  }

  createPopup(): string {
    return this._popupHtml;
  }

  getLocationId(poi: any): number {
    let id: number;
    const item = poi?.results?.features[0];
    if (item?.properties?.poiId) {
      id = item.properties.poiId;
    } else if (item?.properties?.id) {
      id = item.properties.id;
    }
    return id;
  }

  getCategorySearchPoi(query: string, center: any, searchRows: number): Promise<any>{
    return new Promise((resolve, reject) => {
      this.http.get('https://api.mazemap.com/search/equery/?q='+ query +
        '&rows=2000&start='+ searchRows +'&withpois=true&withbuilding=false&withtype=false&withcampus=false' +
        '&campuscollectiontag=universityofauckland&boostbydistance=true&lng=' + center.lng +
        '&lat=' + center.lat)
          .subscribe((response: any) => {
              resolve(response);
          }, error => {
              reject(`Unable to get pois.`);
          });
  });
  }
}
