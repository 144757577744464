import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginSuccessGuard } from '@uoa/auth';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'wayfinding',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: ':type/:code',
    loadChildren: () => import('./pages/space/space.module').then((m) => m.SpaceModule),
  },
  {
    path: 'wayfinding',
    canActivate: [LoginSuccessGuard],
    loadChildren: () => import('./pages/wayfinding/wayfinding.module').then((m) => m.WayfindingPageModule),
  },
  {
    path: 'error/:errorCode',
    loadChildren: () => import('./error-routing.module').then((m) => m.ErrorRoutingModule),
  },
  { path: '**', redirectTo: 'wayfinding' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
