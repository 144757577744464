export enum CategoryType {
  TOILETS = 'Toilets',
  FOOD_DRINK = 'Food & drink',
  STUDY_SPACES = 'Study Spaces',
  PARKING = 'Parking',
  STUDENT_SERVICES = 'Student Services',
}

enum CategoryFilterType {
  TOILETS_FILTER = 'Toilets',
  FOOD_DRINK_FILTER = 'Food & Drink',
  STUDY_SPACES_FILTER = 'Study Spaces',
  PARKING_FILTER = 'Parking',
  STUDENT_SERVICES_FILTER = 'Student Services',
}

export const CategorySearchTrigger = {
  TOILETS: ['toilet', 'toilets', 'bathroom', 'bathrooms', 'restroom', 'restrooms', 'rest room', 'rest rooms', 'lavatory'],
  FOOD_DRINK: ['food', 'foods', 'drink', 'drinks', 'food & drink', 'bar', 'bars', 'takeaways', 'food and drink', 'eating', 'dining'],
  STUDY_SPACES: ['study', 'space', 'study spaces', 'quiet', 'independent', 'group'],
  PARKING: ['parking', 'car park', 'motorbike', 'car', 'disabled parking'],
  STUDENT_SERVICES: ['service', 'student service', 'Ask Auckland', 'AskAuckland', 'student services', 'help', 'information'],
};

/**
 * Category searches are triggered when the user types any of these search triggers,
 * E.g. typing "bathroom" will search for the Toilets category.
 */
export const CategorySearchTriggers: Record<CategoryType, string[]> = {
  [CategoryType.TOILETS]: CategorySearchTrigger.TOILETS,
  [CategoryType.FOOD_DRINK]: CategorySearchTrigger.FOOD_DRINK,
  [CategoryType.PARKING]: CategorySearchTrigger.PARKING,
  [CategoryType.STUDY_SPACES]: CategorySearchTrigger.STUDY_SPACES,
  [CategoryType.STUDENT_SERVICES]: CategorySearchTrigger.STUDENT_SERVICES,
}

export const CategoryFilters: Record<CategoryType, CategoryFilterType> = {
  [CategoryType.TOILETS]: CategoryFilterType.TOILETS_FILTER,
  [CategoryType.FOOD_DRINK]: CategoryFilterType.FOOD_DRINK_FILTER,
  [CategoryType.PARKING]: CategoryFilterType.PARKING_FILTER,
  [CategoryType.STUDY_SPACES]: CategoryFilterType.STUDY_SPACES_FILTER,
  [CategoryType.STUDENT_SERVICES]: CategoryFilterType.STUDENT_SERVICES_FILTER,
}
