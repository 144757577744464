import { Building, Location, Room } from './index';

export interface Campus {
  id: number;
  name: string;
  locations: [Location];
  rooms: [Room];
  buildings: [Building];
}

export const CampusList: Record<number, string> = {
  32: 'City Campus',
  476: 'Newmarket Campus',
  477: 'Grafton Campus',
  478: 'Tai Tonga Campus',
  480: 'Epsom Campus',
  514: 'Tai Tokerau Campus',
  515: 'Leigh',
}
