import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  get deviceType(): Platforms {
    if (this._platform.is('ios')) {
      return 'ios';
    } else if (this._platform.is('android')) {
      return 'android';
    }
    // explicitly return undefined if the platform isn't ios or android.
    return undefined;
  }

  get isMobile(): boolean {
    return this._platform.is('mobile');
  }
  
  get isIOS(): boolean {
    return this._platform.is('ios');
  }

  get isIPad(): boolean {
    return this._platform.is('ipad');
  }

  constructor(private _platform: Platform) {}
}
