import { Injectable } from '@angular/core';

export interface ElementProperties {
  selector?: string;
  showPopUp?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ActiveElementService {
  private _selector: string;
  private _isShowingPopup: boolean;

  elementClicked;

  getActiveElement(elementprop?: ElementProperties) {
    this._selector = elementprop && elementprop.selector ? elementprop.selector : 'button';
    this._isShowingPopup = elementprop && elementprop.showPopUp ? elementprop.showPopUp : false;
    this.elementClicked = document.activeElement;
    return this.elementClicked;
  }

  resetActiveElement() {
    if (this._isShowingPopup) {
      /**
       * When click on button shows pop up, dom can not be traversed for parent,
       *  so have to wait for 250millisecons to set focus back in parent dom.
       */
      setTimeout((_) => this._resetFocus(), 250);
    } else {
      this._resetFocus();
    }
  }

  private _resetFocus() {
    /** Adding selector varaible to avaoid ion-select loop issue as ion-select use button inside shadow dom.
     */
    if (this.elementClicked.shadowRoot && this._selector === 'button') {
      this.elementClicked.shadowRoot.querySelector(this._selector).focus();
    } else {
      this.elementClicked.focus();
    }
  }
}
