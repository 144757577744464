// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  version: '1.0.0-#263',
  auth: {
    cognitoClientId: '7p83q2p44f5kgr4mip3rgool2j',
    cognitoUserPoolId: 'ap-southeast-2_gtuqqgIIq',
    cognitoAwsRegion: 'ap-southeast-2',
    cognitoDomain: 'uoapool-nonprod',
    redirectUri: 'https://maps.dev.auckland.ac.nz',
    logoutUri: 'https://maps.dev.auckland.ac.nz',

    scopes: 'openid profile https://maps.auckland.ac.nz/wayfinding https://student.auckland.ac.nz/timetable.read',
    codeChallengeMethod: 'S256',
  },
  privateUrlKeyWords: {
    whoNeedBearerToken: [{ url: 'apigw.test.amazon.auckland.ac.nz', optional: false }],
  },
  apiServer: 'https://apigw.test.amazon.auckland.ac.nz/',
  googleTag: 'GTM-N8VTJVM',
  useDummyLocation: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
