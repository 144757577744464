import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActiveElementService } from 'src/app/core/services/active-element.service';
import { InfoModal } from 'src/app/domain';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements AfterViewInit {
  @Input() headerIconType: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() description: string;
  @Input() InfoModal: InfoModal;

  @ViewChild('titleLink', { read: ElementRef }) titleLink: ElementRef<HTMLElement>;
  @ViewChild('content', { read: ElementRef }) content: ElementRef<HTMLElement>;
  constructor(private _modalCtrl: ModalController, private _activeElementService: ActiveElementService) {}

  ngAfterViewInit() {
    const helpParentDom = this.content.nativeElement.parentElement.parentElement;
    helpParentDom.style.width = `360px`;

    setTimeout(() => {
      helpParentDom.style.height = `${this.titleLink.nativeElement.parentElement.offsetHeight + 200}px`;
      this.content.nativeElement.focus();
    }, 150);
  }

  close() {
    this._modalCtrl.dismiss();
    this._activeElementService.resetActiveElement();
  }
}
