import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { TimetableService } from 'src/app/core/services';
import { ClassTimetable } from 'src/app/domain';

@UntilDestroy()
@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss'],
})
export class TimetableComponent implements AfterViewInit {
  @ViewChild('title') title: ElementRef;
  timetable$: Observable<ClassTimetable[]>;
  loading$: Observable<boolean>;

  constructor(private _timetableService: TimetableService) {
    this.loading$ = this._timetableService.loading$;
    this.timetable$ = this._timetableService.timetable$.pipe(this._timetableService.filterUpcommingClasses, untilDestroyed(this));
  }

  ngAfterViewInit(): void {
    setTimeout((_) => this.title.nativeElement.focus(), 200);
  }
}
