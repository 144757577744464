import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pwa-help-menu',
  templateUrl: './pwa-help-menu.component.html',
  styleUrls: ['./pwa-help-menu.component.scss'],
})
export class PwaHelpMenuComponent implements AfterViewInit {
  @ViewChild('title') title: ElementRef;
  @Input() deviceType: string;

  ngAfterViewInit(): void {
    setTimeout((_) => this.title.nativeElement.focus(), 200);
  }
}
