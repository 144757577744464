import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Platforms } from '@ionic/core';
import { LoginService, UserInfoDto } from '@uoa/auth';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import {
  ActiveElementService,
  MazemapUIService,
  MenuService,
  PlatformService,
  TimetableService,
} from 'src/app/core/services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  loggedIn$: Observable<boolean>;
  user$: Observable<UserInfoDto>;
  showTimeTable = new Subject<boolean>();
  showPwaHelp = new Subject<boolean>();
  elementClicked: Element;
  recordActiveElement = true;
  deviceType: Platforms;
  
  @ViewChild('title') title: ElementRef;
  @ViewChild('menuTitle') menuTitle: ElementRef;

  get showHelpMenu(): boolean {
    return this.deviceType === 'ios' || this.deviceType === 'android';
  }

  get showMenu(): boolean {
    return this._menuService.showMenu && !this._mazeMapUIService.showMinimalUI;
  }

  constructor(
    private _menuCtrl: MenuController,
    private _loginService: LoginService,
    private _timetableService: TimetableService,
    private _router: Router,
    private _platformService: PlatformService,
    private _activeElementService: ActiveElementService,
    private _menuService: MenuService,
    private _mazeMapUIService: MazemapUIService,
  ) {}

  async ngOnInit() {
    this.deviceType = this._platformService.deviceType;
    await this._loginService.isAuthenticated();
    this.loggedIn$ = this._loginService.loggedIn$;
    this.user$ = this._loginService.userInfo$;
    combineLatest([this.loggedIn$])
      .pipe(
        distinctUntilChanged(),
        filter(([login]) => login === true),
        tap(async (_) => {
          await this._timetableService.getTimetable();
          if (sessionStorage.getItem('showTimetable') === 'true') {
            sessionStorage.removeItem('showTimetable');
            await this.openClassTimetable();
          }
        })
      )
      .subscribe();
  }

  menuWillOpen(): void {
    if (this.recordActiveElement) {
      this.elementClicked = document.activeElement;
    }
    let element: ElementRef;
    this.loggedIn$.pipe(take(1)).subscribe((loggedIn) => {
      if (loggedIn) {
        element = this.title;
      } else {
        element = this.menuTitle;
      }

      element.nativeElement.focus();
    });
  }

  async doLogin() {
    await this._loginService.doLogin(this._router.url);
  }

  openMenu() {
    this._menuCtrl.enable(true, 'first');
    this._menuCtrl.open('first');
    this.showTimeTable.next(false);
  }

  goBackToMainMenu() {
    this.openMenu();
    setTimeout(() => this._activeElementService.resetActiveElement(), 400);
  }

  async openClassTimetable() {
    const authenticate = await this._loginService.doLogin(this._router.url);
    if (authenticate) {
      this.recordActiveElement = false;
      this._activeElementService.getActiveElement();
      this.showTimeTable.next(true);
      await this._menuCtrl.enable(true, 'classTimetable');
      await this._menuCtrl.open('classTimetable');
    } else {
      sessionStorage.setItem('showTimetable', 'true');
    }
  }

  openHelpMenu() {
    this._activeElementService.getActiveElement();
    this._menuCtrl.enable(true, 'pwaHelp');
    this._menuCtrl.open('pwaHelp');
    this.showPwaHelp.next(true);
  }

  resetMenu() {
    this._menuCtrl.enable(true, 'first');
    this.showTimeTable.next(false);
  }

  closeMenu(): void {
    this._menuCtrl.close();
    (this.elementClicked as HTMLElement).focus();
    this.recordActiveElement = true;
    this.showPwaHelp.next(false);
  }
}
