import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PwaHelpMenuComponent } from './pwa-help-menu/pwa-help-menu.component';
import { TimetableModule } from './timetable';

const COMPONENT = [PwaHelpMenuComponent];

@NgModule({
  declarations: [COMPONENT],
  imports: [CommonModule, IonicModule, TimetableModule],
  exports: [TimetableModule, PwaHelpMenuComponent]
})
export class MenuModule {}
