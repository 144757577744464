import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LoadingIndicatorModule } from '../../loading-indicator';
import { ClassComponent } from './class/class.component';
import { TimetableComponent } from './timetable.component';

const COMPONENT = [TimetableComponent, ClassComponent];

@NgModule({
  declarations: [COMPONENT],
  imports: [CommonModule, IonicModule, LoadingIndicatorModule, RouterModule.forChild([{ path: '', component: TimetableComponent }])],
  exports: [TimetableComponent],
})
export class TimetableModule {}
