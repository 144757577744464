import { Component, ElementRef, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() title: string;
  @Input() content: string;

  // ModalComponent is a wrapper for the the help UoA Web Component, it's used to programatically show and hide modals.
  // Docs: https://wiki.auckland.ac.nz/display/AD/Help+Modal+Component
  constructor(private _modalController: ModalController, private _elementRef: ElementRef) {}

  show() {
    // Open the uoa help modal by programatically clicking on the hyperlink
    // (there is no text in this hyperlink to make it invisible to the end user)
    const uoaHelpModal = this._elementRef.nativeElement.querySelector('uoa-help');
    const openHyperLink = uoaHelpModal.shadowRoot.querySelector('a');
    openHyperLink.click();
  }

  async hide() {
    // the uoa help component is built using Ionic modal controller and can be dismissed by
    // calling modalController.dismiss directly
    await this._modalController.dismiss();
  }
}
